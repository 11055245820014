import React from "react";

// Layout
import Weather from "../components/Weather";
const TestWeatherApi = () => {
    return (
        // <Layout location={'test-weather-api'} title={'test'}>
            <Weather></Weather>
        // </Layout>
    );
};

export default TestWeatherApi
